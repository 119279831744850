import React, { useState, useEffect } from "react";
import Input from '../input/Input';
import Textarea from '../input/Textarea';
import DatePicker from '../input/DatePicker';
import Checkbox from '../input/Checkbox';
import Select from '../input/Select';
import Button from '../button';
import Label from './../label';
import { selectData } from '../../services/selectData';
import { getApiLink } from '../../services/getApiLink';
import Categories from './categories.json';
import Upload from './../upload';
import RemoveIcon from './../../svg-components/PlusIcon';
import axios from 'axios';
import Popup from './../popup';
import Radio from './../radio';

const Form = ( { lang } ) => {
    let [ load, setLoad ] = useState(false);
    let [ disabledButton, setDisabledButton ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ buttonText, setButtonText ] = useState("Wyślij zgłoszenie");
    let [ name, setName ] = useState("");
    let [ email, setEmail ] = useState("");
    let [ phone, setPhone ] = useState("");
    let [ address, setAddress ] = useState("");
    let [ dateBirth, setDateBirth ] = useState(false);
    let [ drivingLicence, setDrivingLicence ] = useState(-1);
    let [ experience, setExperience ] = useState([])
    let [ education, setEducation ] = useState([]);
    let [ training, setTraining ] = useState([]);
    let [ cv, setCV ] = useState(false);
    let [ sep, setSep ] = useState(false);
    let [ regulation, setRegulation ] = useState(false);
    let [ newsletter, setNewsletter ] = useState(false);
    let [ reset, setReset ] = useState(false);
    let [ popup, setPopup ] = useState(false);
    let [ firstEmail, setFirstEmail ] = useState(false);
    let [ secondEmail, setSecondEmail ] = useState(false);
    let [ thirdEmail, setThirdEmail ] = useState(false);
    let [ errorEmails, setErrorEmails ] = useState(false);
    let [ netto, setNetto ] = useState("");
    let options = selectData(Categories, lang);
    
    useEffect(() => {
        if(!load) {
            // generateForm();
        }
    }, [load])

    useEffect(() => {
        if(lang == "PL") {
            // if(buttonText == "Надішліть заяву" || buttonText == "Wyślij zgłoszenie") {
            //     setButtonText("Wyślij zgłoszenie")
            // }else{
            //     setButtonText("Wysłano")
            // }
            setButtonText("Wyślij zgłoszenie")
        }else if(lang == "UA") {
            // if(buttonText == "Wyślij zgłoszenie" || buttonText == "Надішліть заяву") {
            //     setButtonText("Надішліть заяву")
            // }else{
            //     setButtonText("Надісланий");
            // }
            setButtonText("Надішліть заяву")
        }
    }, [lang])

    const generateForm = () => {
        setName("Jan Bąk");
        setEmail("jan.bak@norddigital.com");
        setPhone("123123123");
        setAddress("Ulica Janka Bąka Brenna");
        setDateBirth("15/09/1111");
        setDrivingLicence(0);
        setExperience([
            {
                position: "Burmistrz",
                employer: "Bóg",
                dateStart: "12/01/1999",
                dateEnd: "13/12/2222"
            },
            {
                position: "Marynarz",
                employer: "Ryba",
                dateStart: "11/11/1111",
                dateEnd: "22/12/2222"
            }
        ])
        setEducation([
            {
                education: "Niższa",
                science: "WSRH",
                dateEnd: "13/12/2232"
            },
            {
                education: "WYŻSZE",
                science: "zerówka",
                dateEnd: "22/12/2262"
            }
        ])
        setTraining([
            {
                otherCourse: "Zjadanie węży"
            },
            {
                otherCourse: "kradzież torów"
            }
        ])
    }

    const validate = async() => {
        await setForceValidate(true);
        if(!firstEmail && !secondEmail && !thirdEmail) {
            await setErrorEmails(true);
        }else{
            await setErrorEmails(false);
        }
        await wait(100);
        let scroll = await scrollPage();
        if ( !scroll ) {
            await setForceValidate(false);
            await save();
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    const scrollPage = () => {
        let result;
        let elements = document.querySelectorAll('.form .error');
        if( elements.length > 0) {
            let rect = elements[0].getBoundingClientRect();
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: rect.top + scrollTop - 10
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = async() => {
        if ( !disabledButton ) {
            setDisabledButton(true);
            setButtonText(lang == "PL" ? 'Przesyłam formularz...' : 'Відправка...')
            const formData = new FormData(); 
            formData.append("name", name); 
            formData.append("email", email);
            formData.append('phone', phone);
            formData.append('address', address);
            formData.append('dateBirth', dateBirth);
            formData.append('drivingLicence', drivingLicence != -1 ? options.items[drivingLicence].title : false);
            formData.append('experience', JSON.stringify(experience));
            formData.append('education', JSON.stringify(education));
            formData.append('training', JSON.stringify(training));
            formData.append('cv', cv);
            formData.append('regulation', regulation);
            formData.append('newsletter', newsletter);
            formData.append('firstEmail', firstEmail);
            formData.append('secondEmail', secondEmail);
            formData.append('thirdEmail', thirdEmail);
            formData.append('netto', netto);
            formData.append('t', 'fds463assdfsdf322jkS52');
            let ax = await axios({
                method: 'post',

                url: `${ getApiLink() }/sendEmailA.php`,
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                data: formData,
            });
            let res = JSON.parse(ax.request.response);

            if(res.status) {
                await setButtonText(lang == "PL" ? 'Wysłano' : 'Надісланий');
                await setPopup(true);
                await setName("");
                await setPhone("");
                await setEmail("");
                await setAddress("");
                await setDateBirth(false);
                await setDrivingLicence(-1);
                await setExperience([]);
                await setEducation([]);
                await setTraining([]);
                await setCV(false);
                await setSep(false);
                await setErrorEmails(false);
                await setFirstEmail(false);
                await setSecondEmail(false);
                await setThirdEmail(false);
                await setRegulation(false);
                await setNewsletter(false);
                await setReset(true);
                await wait(400);
                await setReset(false);
                // setDisabledButton(false);
            }
        }
    }

    const changeValueIn = (type, index, label, val) => {
        if(type == "experience") {
            let copyData = experience;
            copyData = copyData.map((item, i) => i == index ? ({...item, [label]: val}) : item);
            setExperience(copyData)
        }else if(type == "education") {
            let copyData = education;
            copyData = copyData.map((item, i) => i == index ? ({...item, [label]: val}) : item);
            setEducation(copyData)
        }else if(type == "training") {
            let copyData = training;
            copyData = copyData.map((item, i) => i == index ? ({...item, [label]: val}) : item);
            setTraining(copyData)
        }
    }

    const addNewField = (type) => {
        if(type == "experience") {
            let newExperience = [];
            let newItem = {
                position: "",
                employer: "",
                dateStart: "",
                dateEnd: ""
            }
            if(experience && experience.length > 0) {
                newExperience = [...experience, newItem];
            }else{
                newExperience = [newItem];
            }
            setExperience(newExperience);
        }else if(type == "education") {
            let newEducation = [];
            let newItem = {
                education: "",
                science: "",
                dateEnd: ""
            }
            if(education && education.length > 0) {
                newEducation = [...education, newItem];
            }else{
                newEducation = [newItem];
            }
            setEducation(newEducation);
        }else if(type == "training") {
            let newTraining = [];
            let newItem = {
                otherCourse: "",
            }
            if(training && training.length > 0) {
                newTraining = [...training, newItem];
            }else{
                newTraining = [newItem];
            }
            setTraining(newTraining);
        }
    }

    const removeField = (type, index) => {
        if(type == "experience") {
            let newExperience = experience;
            newExperience = newExperience.filter((item, i) => index == i ? false : item);
            setExperience(newExperience);
        }else if(type == "education") {
            let newEducation = education;
            newEducation = newEducation.filter((item, i) => index == i ? false : item);
            setEducation(newEducation);
        }else if(type == "training") {
            let newTraining = training;
            newTraining = newTraining.filter((item, i) => index == i ? false : item);
            setTraining(newTraining);
        }
    }

    useEffect(() => {
        if(firstEmail || secondEmail || thirdEmail) {
            setErrorEmails(false);
        }
    }, [firstEmail, secondEmail, thirdEmail])

    return (
        <section className="form" id="wyslij-cv">
            {/*<div className="headers">
                {lang == "PL" &&
                    <>
                        <h3>Wybierz przedsiębiorstwo, w którym chciałbyś pracować</h3>
                        <p>Możesz wybrać więcej niż jedną opcję</p>
                    </>
                }
                {lang == "UA" &&
                    <>
                        <h3>Виберіть компанію, в якій ви би хотіли працювати</h3>
                        <p>Ви можете вибрати кілька варіантів</p>
                    </>
                }
            </div>
            <div className="emails-wrapper">
                <div className={`email-box centrum ${firstEmail ? 'active' : ''} ${errorEmails ? 'error' : ''}`} onClick={() => setFirstEmail(!firstEmail)}>
                    <div className="content">
                        {lang == "PL" &&
                            <>
                                <h3>Szczecińskie Przedsiębiorstwo Autobusowe KLONOWICA Sp. z o.o.</h3>
                                <p><strong>Lokalizacja zajezdni</strong>ul. Sebastiana Klonowica 3C 71-241 Szczecin</p>
                                <h3>Centrum</h3>
                            </>
                        }
                        {lang == "UA" &&
                            <>
                                <h3>Szczecińskie Przedsiębiorstwo Autobusowe KLONOWICA Sp. z o.o.</h3>
                                <p><strong>Розташування депо</strong>вул. Себастьяна Клоновіца 3C 71-241 Щецін</p>
                                <h3>Центр</h3>
                            </>
                        }
                    </div>
                    <div className="btn-wrapper">
                        <Button setFunction={() => setFirstEmail(!firstEmail)} title={lang == "PL" ? "Wybierz" : "Виберіть"} arrow />
                    </div>
                </div>
                <div className={`email-box prawobrzeze ${secondEmail ? 'active' : ''} ${errorEmails ? 'error' : ''}`} onClick={() => setSecondEmail(!secondEmail)}>
                    <div className="content">
                        {lang == "PL" &&
                            <>
                                <h3>Szczecińskie Przedsiębiorstwo Autobusowe "Dąbie" Sp. z o.o.</h3>
                                <p><strong>Lokalizacja zajezdni</strong>ul. Andrzeja Struga 10 70-784 Szczecin</p>
                                <h3>Prawobrzeże</h3>
                            </>
                        }
                        {lang == "UA" &&
                            <>
                                <h3>Szczecińskie Przedsiębiorstwo Busowe "Dąbie" Sp. z o.o.</h3>
                                <p><strong>Розташування депо</strong>вул. Анджея Струга 10 70-784 Щецін</p>
                                <h3>Правобережжя</h3>
                            </>
                        }
                    </div>
                    <div className="btn-wrapper">
                        <Button setFunction={() => setSecondEmail(!secondEmail)} title={lang == "PL" ? "Wybierz" : "Виберіть"} arrow />
                    </div>
                </div>
                <div className={`email-box polnoc ${thirdEmail ? 'active' : ''} ${errorEmails ? 'error' : ''}`} onClick={() => setThirdEmail(!thirdEmail)}>
                    <div className="content">
                        {lang == "PL" &&
                            <>
                                <h3>Szczecińsko-Polickie Przedsiębiorstwo Komunikacyjne Sp. z o.o.</h3>
                                <p><strong>Lokalizacja zajezdni</strong>ul. Fabryczna 21 72-010 Police</p>
                                <h3>Północ</h3>
                            </>
                        }
                        {lang == "UA" &&
                            <>
                                <h3>Szczecińsko-Polickie Przedsiębiorstwo Komunikacyjne Sp. z o.o.</h3>
                                <p><strong>Розташування депо</strong>вул. Фабрична 21 72-010 Поліце</p>
                                <h3>Північ</h3>
                            </>
                        }
                    </div>
                    <div className="btn-wrapper">
                        <Button setFunction={() => setThirdEmail(!thirdEmail)} title={lang == "PL" ? "Wybierz" : "Виберіть"} arrow />
                    </div>
                </div>
            </div>
            {errorEmails &&
                <div className="validate-emails">
                    <p class="error-text error">{lang == "PL" ? "Należy wybrać przynajmniej jedną z opcji." : "Ви повинні вибрати хоча б один із варіантів."}</p>
                </div>
            }*/}
            <div className="headers">
                {lang == "PL" &&
                    <>
                        <h3>Jaką kwotę netto („na rękę”) chciałbyś zarabiać na tym stanowisku?</h3>
                    </>
                }
                {lang == "UA" && 
                    <>
                        <h3>Скільки чистої суми ви хотіли б отримувати на цій посаді?</h3>
                    </>
                }
                <div className="number-wrapper">
                    <Input
                        placeholder={lang == "PL" ? "kwota" : "сума"}
                        required={ false }
                        type="text" 
                        name="netto"
                        regExp={/^[0-9]*$/}
                        allowChars={/.*/}
                        minChar={ 1 } 
                        forceValidate={ forceValidate }
                        reset={reset}
                        value={netto}
                        lang={lang}
                        setFunction={ ( val ) => setNetto(val)} />
                </div>
            </div>
            <form>
                {popup &&
                    <Popup text={lang == "PL" ? "<h2>Twoje zgłoszenie zostało pomyślnie wysłane</h2>" : "<h2>Ваша заявка успішно подана.</h2>"} close={() => setPopup(false)} />
                }
                {/* DANE */}
                <div className="group">
                    <Label text={lang == "PL" ? "Twoje dane" : "Ваші дані"} />
                    <div className="fields">
                        <div className="row-item">
                            <Input
                                placeholder={lang == "PL" ? "imię i nazwisko" : "Ім'я та прізвище"}
                                required={ true }
                                type="text" 
                                name="name"
                                allowChars={/.*/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={name}
                                lang={lang}
                                setFunction={ ( val ) => setName(val)} />
                        </div>
                        <div className="row-item">
                            <Input
                                placeholder={lang == "PL" ? "adres e-mail" : "електронна пошта"}
                                required={ true }
                                type="text" 
                                name="email"
                                regExp={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/}
                                allowChars={/.*/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={email}
                                lang={lang}
                                setFunction={ ( val ) => setEmail(val)} />
                        </div>
                        <div className="row-item">
                            <Input
                                placeholder={lang == "PL" ? "nr telefonu" : "номер телефону"}
                                required={ true }
                                type="text" 
                                name="phone"
                                regExp={/^[+0-9]{9,13}$/}
                                allowChars={/^[+0-9]{0,13}$/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={phone}
                                lang={lang}
                                setFunction={ ( val ) => setPhone(val)} />
                        </div>
                        <div className="row-item">
                            <Input
                                placeholder={lang == "PL" ? "adres" : "адреса"}
                                required={ true }
                                type="text" 
                                name="address"
                                allowChars={/.*/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={address}
                                lang={lang}
                                setFunction={ ( val ) => setAddress(val)} />
                        </div>
                        <div className="row-item">
                            <DatePicker required forceValidate={forceValidate} lang={lang} value={dateBirth} setFunction={(val) => setDateBirth(val)} placeholder={lang == "PL" ? "Data urodzenia (dd/mm/rrrr)" : "дата народження (дд/мм/рррр)"} minAge="21"  reset={reset} />
                        </div>
                    </div>
                    {/* <div className="row-big-item">
                        <div className="wrapper-radio-btns">
                            <label><p>Mile widziane: </p> posiadam uprawnienia SEP</label>
                            <Radio text={"TAK"} name="SEP" setFunction={(val) => setSep(val)} value={sep} />
                            <Radio text={"NIE"} name="SEP" setFunction={(val) => setSep(val)} value={sep} />
                        </div>
                        {(sep == "NIE" || errorSep) &&
                            <p class="error-text error">Uprawnienia SEP są wymagane przy składaniu aplikacji</p>
                        }
                    </div> */}
                </div>
                {/* DOŚWIADCZENIE */}
                <div className="group">
                    <Label text={lang == "PL" ? "Doświadczenie zawodowe" : "Професійний досвід"} info={lang == "PL" ? "Możesz dodać swoje doświadczenie zawodowe, ale nie jest to wymagane" : "Ви можете додати свій професійний досвід, але це не обов'язково"} arrow={true} actionClass="fields-1" clickToggle={() => experience.length == 0 ? addNewField("experience") : false} />
                    <div className="fields fields-1 hidden" id="fields-1">
                        {experience && experience.map((item, index) => (
                            <>
                            <div className="row-item">
                                <Input
                                    placeholder={lang == "PL" ? "Stanowisko" : "Посада"}
                                    required={ false }
                                    type="text" 
                                    name="position"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.position}
                                    lang={lang}
                                    setFunction={ ( val ) => changeValueIn("experience", index, "position", val)} />
                            </div>
                            <div className="row-item">
                                <Input
                                    placeholder={lang == "PL" ? "Pracodawca" : "Роботодавець"}
                                    required={ false }
                                    type="text" 
                                    name="employer"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.employer}
                                    lang={lang}
                                    setFunction={ ( val ) => changeValueIn("experience", index, "employer", val)} />
                            </div>
                            <div className="row-item dates">
                                <DatePicker lang={lang} value={item.dateStart} setFunction={(val) => changeValueIn("experience", index, "dateStart", val)} placeholder={lang == "PL" ? "dd/mm/rrrr" : "дд/мм/рррр"} title={lang == "PL" ? "Data rozpoczęcia" : "Дата початку"}  reset={reset} />
                                <DatePicker lang={lang} value={item.dateEnd} setFunction={(val) => changeValueIn("experience", index, "dateEnd", val)} placeholder={lang == "PL" ? "dd/mm/rrrr" : "дд/мм/рррр"} title={lang == "PL" ? "Data zakończenia" : "Дата закінчення"}  reset={reset} />
                                <div className="remove-item" onClick={() => removeField("experience", index)}>
                                    <RemoveIcon />
                                </div>
                            </div>
                            <div className="mobile-border" />
                            </>
                        ))}
                    </div>
                    <div className="row-item fields-1 hidden">
                        <Button setFunction={() => addNewField("experience")} title={lang == "PL" ? "Dodaj kolejne" : "Додати більше"} plus />
                    </div>
                </div>
                {/* WYKSZTAŁCENIE */}
                <div className="group">
                    <Label text={lang == "PL" ? "Wykształcenie" : "Освіта"} info={lang == "PL" ? "Możesz dodać swoje wykształcenie, ale nie jest to wymagane" : "Ви можете додати свою освіту, але це не обов’язково"} arrow={true} actionClass="fields-2" clickToggle={() => education.length == 0 ? addNewField("education") : false} />
                    <div className="fields fields-2 hidden" id="fields-2">
                        {education && education.map((item, index) => (
                            <>
                            <div className="row-item">
                                <Input
                                    placeholder={lang == "PL" ? "Wykształcenie" : "Освіта"}
                                    required={ false }
                                    type="text" 
                                    name="education"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.education}
                                    lang={lang}
                                    setFunction={ ( val ) => changeValueIn("education", index, "education", val)} />
                            </div>
                            <div className="row-item">
                                <Input
                                    placeholder={lang == "PL" ? "Kierunek/klasa" : "Напрямок/клас"}
                                    required={ false }
                                    type="text" 
                                    name="science"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.science}
                                    lang={lang}
                                    setFunction={ ( val ) => changeValueIn("education", index, "science", val)} />
                            </div>
                            <div className="row-item date">
                                <DatePicker lang={lang} value={item.dateEnd} setFunction={(val) => changeValueIn("education", index, "dateEnd", val)} placeholder={lang == "PL" ? "dd/mm/rrrr" : "дд/мм/рррр"} title={lang == "PL" ? "Data zakończenia" : "Дата закінчення"} reset={reset} />
                                <div className="remove-item" onClick={() => removeField("education", index)}>
                                    <RemoveIcon />
                                </div>
                            </div>
                            <div className="mobile-border" />
                            </>
                        ))}
                    </div>
                    <div className="row-item fields-2 hidden">
                        <Button setFunction={() => addNewField("education")} title={lang == "PL" ? "Dodaj kolejne" : "Додати більше"} plus />
                    </div>
                </div>
                {/* KURSY / SZKOLENIA */}
                <div className="group">
                    <Label text={lang == "PL" ? "Kursy / szkolenia" : "Навчальні курси"} info={lang == "PL" ? "Możesz dodać np. informacje o posiadanym prawie jazdy, ale nie jest to wymagane" : "Ви можете додати, наприклад, інформацію про своє водійське посвідчення, але це не обов’язково"} arrow={true} actionClass="fields-3" clickToggle={() => training.length == 0 ? addNewField("training") : false} />
                    <div className="fields fields-3 hidden">
                        <div className="row-item">
                            <Select data={ options } 
                                selectIndex={ drivingLicence }
                                reset={reset}
                                lang={lang}
                                setFunction={ ( val ) => setDrivingLicence(val)} />
                        </div>
                        <div className="row-item hidden-item" />
                        <div className="row-item hidden-item" />
                    </div>
                    <div className="fields fields-3 hidden" id="fields-3">
                        {training && training.map((item, index) => (
                            <>
                            <div className="row-item">
                                <Input
                                    placeholder={lang == "PL" ? "Inne kursy" : "Інші курси"}
                                    required={ false }
                                    type="text" 
                                    name="other-course"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.otherCourse}
                                    lang={lang}
                                    setFunction={ ( val ) => changeValueIn("training", index, "otherCourse", val)} />
                                    <div className="remove-item" onClick={() => removeField("training", index)}>
                                        <RemoveIcon />
                                    </div>
                            </div>
                            <div className="row-item hidden-item" />
                            <div className="row-item hidden-item" />
                            <div className="mobile-border" />
                            </>
                        ))}
                    </div>
                    <div className="row-item fields-3 hidden">
                        <Button setFunction={() => addNewField("training")} title={lang == "PL" ? "Dodaj kolejne" : "Додати більше"} plus />
                    </div>
                </div>
                {/* ZAŁADUJ CV */}
                <div className="group">
                    <Label text={lang == "PL" ? "Załaduj CV" : "Завантажте своє резюме"} arrow={true} actionClass="fields-4" info={lang == "PL" ? "Możesz dodać swoje CV, ale nie jest to wymagane." : "Ви можете додати своє резюме, але це не обов’язково."} />
                    <div className="finish fields-4 hidden" id="fields-4">
                        <Upload  
                            setFunction={ val => setCV( val ) }
                            value={ cv }
                            regExp={ /.*/ }
                            lang={lang}
                        />
                    </div>
                    <div className="row-item">
                        <Checkbox text={lang == "PL" ? "Wyrażam zgodę na przetwarzanie moich danych zgodnie z <a target='__blank' href='https://bip.ts.szczecin.pl/artykul/klauzula-informacyjna'>klauzulą informacyjną</a>" : "Я даю згоду на обробку моїх даних відповідно до <a target='__blank' href='https://bip-ts-szczecin-pl.translate.goog/artykul/przetwarzanie-danych-osobowych?_x_tr_sl=pl&_x_tr_tl=uk'>інформаційного положення</a>"} 
                            required={ true }
                            forceValidate={ forceValidate }
                            lang={lang}
                            setFunction={ ( val ) => setRegulation(val)}
                            reset={ reset } />
                    </div>
                    <div className="row-item">
                        <Checkbox text={lang == "PL" ? "Chcę otrzymywać Newsletter" : "Я хочу отримувати розсилку"} 
                            required={ false }
                            forceValidate={ forceValidate }
                            lang={lang}
                            setFunction={ ( val ) => setNewsletter(val)}
                            reset={ reset } />
                    </div>
                    <div className="row-item submit">
                        <Button setFunction={() => validate()} title={buttonText} arrow />
                    </div>
                </div>
            </form>
        </section>
    );
};

export default Form;