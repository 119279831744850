import React, { useState, useEffect } from "react"

export default (props) => {
  let [ error, setError ] = useState( false );
  let [ load, setLoad ] = useState( false );
  let [ errorText, setErrorText ] = useState( false );

  useEffect(() => {
    if(props.lang == "PL"){
      if(errorText == "Недостатньо символів" || errorText == "Za mało znaków") {
        setErrorText("Za mało znaków");
      }
      if(errorText == "Це поле є обов'язковим" || errorText == "To pole jest wymagane"){
        setErrorText("To pole jest wymagane")
      }
      if(errorText == "Неправильний формат" || errorText == "Zły format"){
        setErrorText("Zły format")
      }
    }else if(props.lang == "UA") {
      if(errorText == "Za mało znaków" || errorText == "Недостатньо символів") {
        setErrorText("Недостатньо символів");
      }
      if(errorText == "To pole jest wymagane" || errorText == "Це поле є обов'язковим"){
        setErrorText("Це поле є обов'язковим")
      }
      if(errorText == "Zły format" || errorText == "Неправильний формат"){
        setErrorText("Неправильний формат")
      }
    }
  }, [props.lang])

  useEffect(() => {
    if ( load ) {
      setError(false);
      setErrorText(false);
      setLoad(false)
    }
  }, [ props.reset])

  useEffect(() => {
    if ( !load ) {
      setLoad(true);
    }
  }, [load])

  useEffect(() => {
    if ( load ) {
        validate();
    }
  }, [props.forceValidate])

  const validate = () => {
        let val = props.value;

      // check if empty
      if ( props.required && val && val.length == 0) {
            setErrorText(props.lang == "PL" ? 'To pole jest wymagane' : "Це поле є обов'язковим");
            setError(true);
            return false;
        } else {
            setError(false);
        }

        //check length 
        if ( props.required && val.length < props.minChar ) {
            setError(true);       
            setErrorText(props.lang == "PL" ? 'Za mało znaków' : "Недостатньо символів");
            return false;
        } else {
            setError(false);
        }


        //check format
        if ( props.required && props.regExp ) {
            
            let regExp = new RegExp(props.regExp);

            if(regExp.test(val)) {
                setError(false);
            } else {
                setError(true);       
                setErrorText(props.lang == "PL" ? 'Zły format' : "Неправильний формат");
                return false;
            }
        }

        return true;
        
  }

  return (
    <div className={"input-wrap " + (props.class ? props.class + ' ' : '') + (props.type ? props.type + ' ' : '') + ( error ? 'error ' : '') }>
        {props.title &&
          <label for={ props.name }>{ props.title }{ props.required ? '*' : '' }</label>
        }
        <input id={ props.name } autoComplete="off" type={ props.type ? props.type : 'text' } placeholder={ props.placeholder } onChange={ (e) => props.setFunction(e.target.value) } onBlur={ (e) => validate() } value={ props.value } />
        { error && errorText && errorText.length > 0 && !props.disableErrorText &&
          <p className="error-text">
            { errorText }
          </p>
        }
    </div>
  )
}
