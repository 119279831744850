import React, { useState } from "react";
import Logo from './../../svg-components/Logo';
import LogoImage from './../../images/logo-szczecin.svg';
import { Link } from "react-router-dom";

const Header = ( { history, setLang, lang} ) => {
    let [ langLists, setLangLists ] = useState(["PL", "UA"])

    const onClick = (id) => {
        const anchorComment = document.getElementById(id);
        if(anchorComment) {
            let rect = anchorComment.getBoundingClientRect();
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: rect.top + scrollTop - 10
            });
        }
    }

    return (
        <header>
            <div className="logo">
                {/* <Logo /> */}
                <img src={LogoImage} alt="szczecin" />
            </div>
            <nav>
                <ul>
                    <li>
                        
                        {lang == "PL" &&
                            <a href="#" onClick={() => onClick("wyslij-cv")}>Wyślij zgłoszenie</a>        
                        }
                        {lang == "UA" &&
                            <a href="#" onClick={() => onClick("wyslij-cv")}>Надішліть заяву</a>
                        }
                    </li>
                    {/* <li>
                        <a href="#" onClick={() => onClick("o-kursie")}>O kursie</a>
                    </li> */}
                </ul>
                <div className="lang-switcher">
                    {langLists && langLists.map((langItem, index) => {
                        if(langItem != lang) {
                            return (
                                <Link to={langItem == "PL" ? "/" : "/"+langItem.toLocaleLowerCase()}>
                                    <p>{langItem}</p>
                                </Link>
                            )
                        }
                    })}
                </div>
            </nav>
        </header>
    )

}



export default Header;