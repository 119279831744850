import React, { } from "react"

const Footer = ( { lang } ) => {

    return (
        <footer>
            <div className="container">
                {lang == "PL" &&
                    <p>© Miasto Szczecin. Wszystkie prawa zastrzeżone.</p>
                }
                {lang == "UA" &&
                    <p>© Місто Щецін. Всі права захищені.</p>
                }
            </div>
        </footer>
    )

}



export default Footer;