import React, { useState, useEffect } from "react"

const Boxes = ( { lang } ) => {
    let [ boxes, setBoxes ] = useState([
        {
            title: "Wymagania:",
            desc: `<ul>
                <li>Min. 21 lat,</li>
                <li>prawa jazdy  kat. D + kwalifikacja zawodowa<br /><strong>(możliwość realizacji kursu na kat. D w spółce),</strong></li>
                <li>niekaralność,</li>
                <li>odporność na stres,</li>
                <li>dyspozycyjność,</li>
                <li>komunikatywność,</li>
                <li>sumienność,</li>
                <li>wysoka kultura osobista.</li>
            </ul>`
        }, 
        {
            title: "Co zyskujesz:",
            desc: `<ul>
                <li>Kurs prawa jazdy na kat. D,</li>
                <li>stabilne zatrudnienie: umowa o pracę w dużej, stabilnej i perspektywicznej firmie,</li>
                <li>świadczenia socjalne (m. in. bony świąteczne, dofinansowanie wypoczynku),</li>
                <li>dofinansowanie zajęć sportowych (na zasadach określonych w ZFŚS),</li>
                <li>dofinansowanie szkoleń i kursów,</li>
                <li>atrakcyjną pensję + dodatki (m.in.: nadgodziny, premia regulaminowa, dodatki nocne, dodatki świąteczne) na podstawie m.in. pozytywnej oceny pracy, zaangażowania w pracę oraz niskiej absencji możliwość uzyskania rocznej nagrody specjalnej.</li>
            </ul>`
        },
        {
            title: "Zakres obowiązków:",
            desc: `<ul>
                <li>bezpieczny przewóz pasażerów na liniach komunikacyjnych,</li>
                <li>zgłaszanie zauważonych w czasie jazdy usterek i uszkodzeń pojazdu,</li>
                <li>informowanie pracowników nadzoru ruchu o wystąpieniu utrudnień na trasie,</li>
                <li>obsługa klienta.</li>
            </ul>`
        }
    ]);

    useEffect(() => {
        if(lang == "PL") {
            setBoxes([
                {
                    title: "Wymagania:",
                    desc: `<ul>
                        <li>Min. 21 lat,</li>
                        <li>prawa jazdy  kat. D + kwalifikacja zawodowa<br /><strong>(możliwość realizacji kursu na kat. D w spółce),</strong></li>
                        <li>niekaralność,</li>
                        <li>odporność na stres,</li>
                        <li>dyspozycyjność,</li>
                        <li>komunikatywność,</li>
                        <li>sumienność,</li>
                        <li>wysoka kultura osobista.</li>
                    </ul>`
                },
                {
                    title: "Co zyskujesz:",
                    desc: `<ul>
                        <li>Kurs prawa jazdy na kat. D,</li>
                        <li>stabilne zatrudnienie: umowa o pracę w dużej, stabilnej i perspektywicznej firmie,</li>
                        <li>świadczenia socjalne (m. in. bony świąteczne, dofinansowanie wypoczynku),</li>
                        <li>dofinansowanie zajęć sportowych (na zasadach określonych w ZFŚS),</li>
                        <li>dofinansowanie szkoleń i kursów,</li>
                        <li>atrakcyjną pensję + dodatki (m.in.:  nadgodziny, premia regulaminowa, dodatki nocne, dodatki świąteczne) na podstawie m.in. pozytywnej oceny pracy, zaangażowania w pracę oraz niskiej absencji możliwość uzyskania rocznej nagrody specjalnej.</li>
                    </ul>`
                },
                {
                    title: "Zakres obowiązków:",
                    desc: `<ul>
                        <li>bezpieczny przewóz pasażerów na liniach komunikacyjnych,</li>
                        <li>zgłaszanie zauważonych w czasie jazdy usterek i uszkodzeń pojazdu,</li>
                        <li>informowanie pracowników nadzoru ruchu o wystąpieniu utrudnień na trasie,</li>
                        <li>obsługa klienta.</li>
                    </ul>`
                }
            ])
        }else if(lang == "UA") {
            setBoxes([
                {
                    title: "Вимоги:",
                    desc: `<ul>
                        <li>вік щонайменше 21 рік,</li>
                        <li>водійське посвідчення категорії D + професійна кваліфікація<br /><strong>(можливість проходження курсу категорії D в компанії),</strong></li>
                        <li>відсутність судимості,</li>
                        <li>стійкість до стресів,</li>
                        <li>готовність до роботи,</li>
                        <li>комунікативні навички,</li>
                        <li>сумлінність,</li>
                        <li>висока особиста культура.</li>
                    </ul>`
                },
                {
                    title: "Що ви отримаєте натомість:",
                    desc: `<ul>
                        <li>Курс отримання водійських прав категорії D,</li>
                        <li>стабільне працевлаштування: трудовий договір у великій, стабільній та перспективній компанії,</li>
                        <li>соціальні виплати (зокрема різдвяні ваучери, дофінансування відпочинку),</li>
                        <li>дофінансування спортивних занять (за принципами, визначеними у Фонді соціальних виплат),</li>
                        <li>дофінансування навчання та курсів,</li>
                        <li>приваблива заробітна плата + надбавки (включаючи понаднормові, законодавчі премії, нічні надбавки, різдвяні надбавки) на основі, серед іншого, позитивна оцінка роботи, відданість роботі та низькі прогули, можливість отримання щорічної спеціальної премії.</li>
                    </ul>`
                },
                {
                    title: "Обов'язки:",
                    desc: `<ul>
                        <li>безпечне перевезення пасажирів на лініях комунікації,</li>
                        <li>повідомлення про поломки та пошкодження автомобіля, помічені під час руху,</li>
                        <li>інформування співробітників інспекції руху про будь-які труднощі на маршруті,</li>
                        <li>обслуговування клієнтів.</li>
                    </ul>`
                }
            ])
        }
      }, [lang])

    return (
        <section class="boxes">
            <div className="container">
                <div className="boxes-wrapper">
                    {boxes && boxes.map((box, index) => (
                        <div className="box">
                            <div className="icon">
                                <p>{++index}</p>
                            </div>
                            <div className="title">
                                <h3>{box.title}</h3>
                            </div>
                            <div className="desc" dangerouslySetInnerHTML={ { __html: box.desc } } />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )

}



export default Boxes;