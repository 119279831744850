import React, { useState, useEffect } from 'react';


const PageNotFound = ( { lang } ) => {

  return (
    <>
        <h1>{ lang == "UA" ? "STRONA NIE ISTNIEJE" : "СТОРІНКА НЕ ІСНУЄ" }</h1>
    </>
  );

}

export default PageNotFound;
