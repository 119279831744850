import React, { useState, useEffect } from "react"
import 'moment/locale/pl';
import 'moment/locale/uk';
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import moment from 'moment';

export default props => {
  let [ date, setDate ] = useState("");
  let [ error, setError ] = useState( false );
  let [ load, setLoad ] = useState( false );
  let [ errorText, setErrorText ] = useState( false );

  useEffect(() => {
    if(props.lang == "PL"){
      if(errorText == "Це поле є обов'язковим" || errorText == "To pole jest wymagane") {
        setErrorText("To pole jest wymagane")
      }
      if(errorText == "Неправильний формат" || errorText == "Zły format") {
        setErrorText("Zły format")
      }
      if(errorText == `Необхідний вік є ${props.minAge} років` || errorText == `Wymagany wiek to ${props.minAge} lat`) {
        setErrorText(`Wymagany wiek to ${props.minAge} lat`)
      }
    }else if(props.lang == "UA") {
      if(errorText == "To pole jest wymagane" || errorText == "Це поле є обов'язковим") {
        setErrorText("Це поле є обов'язковим")
      }
      if(errorText == "Zły format" || errorText == "Неправильний формат") {
        setErrorText("Неправильний формат")
      }
      if(errorText == `Wymagany wiek to ${props.minAge} lat` || errorText == `Необхідний вік є ${props.minAge} років`) {
        setErrorText(`Необхідний вік є ${props.minAge} років`)
      }
    }
    moment.locale(props.lang == "PL" ? "pl" : "uk"); 
  }, [props.lang])

  useEffect(() => {
    if ( load ) {
        moment.locale(props.lang == "PL" ? "pl" : "uk"); 
        setLoad(false)
    }
  }, [ props.reset])

  useEffect(() => {
    if ( !load ) {
    //   getTodayDate();
        if(props.value) {
          setDate(props.value);
        }else{
          setDate("")
        }
        setError(false);
        setErrorText(false);
        setLoad(true);
    }
  }, [load])

  useEffect(() => {
    if(load && date) {
        props.setFunction(date);
        validate();
    }
  }, [load, date])

  useEffect(() => {
    if ( load ) {
        validate();
    }
  }, [props.forceValidate])

  const checkDate = (addYears) => {
    addYears = parseInt(addYears);
    let dateArray = date.split("/");
    let day = parseInt(dateArray[0]);
    let month = parseInt(dateArray[1]);
    let year = parseInt(dateArray[2]);
    let newDate = new Date(year+addYears, month-1, day);
    return newDate <= new Date();
  }

  const validate = () => {
      let val = date;
      // check if empty
      if ( props.required && val.length === 0) {
          setErrorText(props.lang == "PL" ? 'To pole jest wymagane' : "Це поле є обов'язковим");
          setError(true);
          return false;
      } else {
          setError(false);
          setErrorText("");
      }

      if(props.required && props.minAge) {
          if(!checkDate(props.minAge)) {
            setError(true);       
            setErrorText(props.lang == "PL" ? `Wymagany wiek to ${props.minAge} lat` : `Необхідний вік є ${props.minAge} років`);
            return false;
          }else{
            setError(false);
            setErrorText("");
          }
      }

        //check length 
        // if ( props.required && val.length < props.minChar ) {
        //     setError(true);       
        //     setErrorText('Za mało znaków');
        //     return false;
        // } else {
        //     setError(false);
        // }


        //check format
        if ( props.required && props.regExp ) {
            
            let regExp = new RegExp(props.regExp);

            if(regExp.test(val)) {
                setError(false);
            } else {
                setError(true);       
                setErrorText(props.lang == "PL" ? 'Zły format' : "Неправильний формат");
            }
        }

        return true;
        
  }

  return (
    <div className={"input-date-picker-wrap " + (props.class ? props.class + ' ' : '') + (props.type ? props.type + ' ' : '') + ( error ? 'error ' : '') }>
        {props.title &&
          <label for={ props.name }>{ props.title }{ props.required ? '*' : '' }</label>
        }
        <DatePickerInput
            onChange={(jsDate, dateString) => setDate(dateString)}
            value={date}
            className="date-input"
            // locale={pl}
            // locale={props.lang == "PL" ? "pl-PL" : "ua"}
            displayFormat="DD/MM/YYYY"
            returnFormat="DD/MM/YYYY"
            placeholder={props.placeholder}
            showOnInputClick
            onKeyDown={(e) => {
                e.preventDefault();
            }}
        />
        { error && errorText && errorText.length > 0 && !props.disableErrorText &&
          <p className="error-text">
            { errorText }
          </p>
        }
    </div>
  )
}
