import React, { } from "react"
import Header from './../components/header';
import Footer from './../components/footer';

const Layout = ( { children, setLang, lang } ) => {

    return (
        <div className="layout">
            <Header setLang={(val) => setLang(val)} lang={lang} />
            { children }
            <Footer lang={lang} />
        </div>
    )

}



export default Layout;