import React, { useState, useEffect } from 'react';
import Form from './../components/form';
import Banner from './../components/banner';
import Title from './../components/title';
import Boxes from './../components/boxes';
import Editor from './../components/editor';
import Video1 from './../images/autobus1.mp4';
import Video2 from './../images/autobus2.mp4';
import Video3 from './../images/autobus3.mp4';

const Home = ( { lang } ) => {

  let [ load, setLoad ] = useState(false);
  let [ video, setVideo ] = useState(false);
  let [ firstTitle, setFirstTitle ] = useState("DOŁĄCZ DO ZESPOŁU I ZOSTAŃ KIEROWCĄ AUTOBUSU W SZCZECINIE");
  let [ secondTitle, setSecondTitle ] = useState("Jak zostać kierowcą?");
  let [ editor, setEditor ] = useState(`<ol>
    <li>Wypełnij poniższy formularz: wystarczy, że podasz nam tylko swoje dane.</li>
    <li>Możesz, ale nie musisz dodać informacje o: doświadczeniu zawodowym, wykształceniu, dodatkowych kursach. Jeśli masz gotowe CV możesz też je załączyć, ale nie jest to wymagane do przesłania zgłoszenia.</li>
    <li>Po otrzymaniu zgłoszenia skontaktujemy się z Tobą w celu zaproszenia na rozmowę kwalifikacyjną.</li>
    <li>Po pomyślnym przejściu procesu kwalifikacji będziesz mógł podjąć pracę w spółce.</li>
  </ol>`);

  useEffect(() => {
    if(lang == "PL") {
      setFirstTitle("DOŁĄCZ DO ZESPOŁU I ZOSTAŃ KIEROWCĄ AUTOBUSU W SZCZECINIE");
      setSecondTitle("Jak zostać kierowcą?");
      setEditor(`<ol>
        <li>Wypełnij poniższy formularz: wystarczy, że podasz nam tylko swoje dane.</li>
        <li>Możesz, ale nie musisz dodać informacje o: doświadczeniu zawodowym, wykształceniu, dodatkowych kursach. Jeśli masz gotowe CV możesz też je załączyć, ale nie jest to wymagane do przesłania zgłoszenia.</li>
        <li>Po otrzymaniu zgłoszenia skontaktujemy się z Tobą w celu zaproszenia na rozmowę kwalifikacyjną.</li>
        <li>Po pomyślnym przejściu procesu kwalifikacji będziesz mógł podjąć pracę w spółce.</li>
      </ol>`);
    }else if(lang == "UA") {
      setFirstTitle("ПРИЄДНАЙТЕСЯ ДО КОМАНДИ І СТАНЬТЕ ВОДІЄМ АВТОБУСА В ЩЕЦІНІ");
      setSecondTitle("ЯК СТАТИ ВОДІЄМ?");
      setEditor(`<ol>
        <li>Заповніть форму нижче: все, що вам потрібно зробити, це надати нам свої дані.</li>
        <li>Ви можете, але не обов'язково, додати інформацію про: професійний досвід, освіту, додаткові курси. Якщо у вас є готове резюме, ви також можете прикріпити його, але це не обов’язково для подання заяви.</li>
        <li>Після отримання вашої заяви ми зв'яжемося з вами, щоб запросити на співбесіду.</li>
        <li>Після успішного проходження процесу працевлаштування ви зможете приступити до роботи в компанії.</li>
      </ol>`);
    }
  }, [lang])

  useEffect(() => {
    if(!load) {
        randVideo();
        setLoad(true);
    }
  }, [load])

  const randVideo = () => {
      let array = [Video1, Video2, Video3];
      let randomNumber = Math.floor(Math.random() * (2 - 0 + 1) + 0);
      setVideo(array[randomNumber]);
  }
  return (
    <>
      {video &&
        <Banner video={video} lang={lang} />
      }
      <Title text={firstTitle} color="red" bold id="o-kursie" />
      <Boxes lang={lang} />
      <div className="bg-sections">
        <Title text={secondTitle} color="blue" marginTop="30" marginBottom="18" />
        <Editor text={editor} />
        <Form lang={lang} />
      </div>
    </>
  );

}

export default Home;
