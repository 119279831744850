import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Layout from './components/Layout';
import HomePage from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import TagManager from 'react-gtm-module';
import getLangFromUrl from './services/getLangFromUrl';

function App(props) {
  let [ lang, setLang ] = useState("PL");
  let location = useLocation();

  useEffect(() => {
    setLang(getLangFromUrl(location.pathname).toUpperCase());
  }, [location])

  const tagManagerArgs = {
    gtmId: 'GTM-TW9LNXSF'
  }
 
  TagManager.initialize(tagManagerArgs)
  console.log(props)
  return (
    <Layout setLang={(lang) => setLang(lang)} lang={lang}>
      <Routes>
        <Route path="/" element={ <HomePage lang={"PL"} />} />
        <Route path="/ua" element={ <HomePage lang={"UA"} />} />
        <Route path="/pl" element={ <HomePage lang={"PL"} />} />
        <Route path="*" element={ <PageNotFound lang={lang} />} />
      </Routes>
    </Layout>
  );
}

export default App;
